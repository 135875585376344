var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "myfs-portal", attrs: { id: "app" } },
    [
      _c("the-overlay"),
      _vm.isUpgradeToTotalAllowed ? _c("the-upgrade-popup") : _vm._e(),
      _vm.customerIsCompleteAndLoggedIn
        ? _c("the-subscription-reminder-popup", {
            attrs: { "evaluate-show-on-mount": _vm.runReminderPopup },
          })
        : _vm._e(),
      _vm.showActivationPoup
        ? _c("the-additional-service-activation-popup")
        : _vm._e(),
      _c("the-additional-service-activation-instructions-popup"),
      _vm.customerIsInactive
        ? _c("the-inactive-popup", {
            attrs: { "inactive-show-on-mount": _vm.runInactivePopup },
          })
        : _vm._e(),
      _c(
        "transition",
        { attrs: { name: "fade" } },
        [
          _c("loading-view", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.showLoading,
                expression: "showLoading",
              },
            ],
            staticClass: "loading-view",
          }),
        ],
        1
      ),
      _c(
        "transition",
        { attrs: { name: "fade" } },
        [
          _c(
            "keep-alive",
            [
              !_vm.waitForRedirect
                ? _c("router-multi-view", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: !_vm.showLoading,
                        expression: "!showLoading",
                      },
                    ],
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }