export default {
  getAll() {
    let cookieMap = {}
    let cookieItems = document.cookie.split(';')

    for (const cookieEntry of Object.entries(cookieItems)) {
      const cookieItem = cookieEntry[1]
      let keyAndValue = cookieItem.split('=')
      cookieMap[keyAndValue[0].replace(' ', '')] = keyAndValue[1]
    }

    return cookieMap
  },
  launchCookiePoll(interval, cb) {
    let detectCookiesUpdate = (function a() {
      let previousCookieData = document.cookie

      return function b() {
        let currentCookieData = document.cookie

        if (currentCookieData !== previousCookieData) {
          cb()
          previousCookieData = currentCookieData
        }
      }
    })()

    return window.setInterval(detectCookiesUpdate, interval)
  }
}
