import DataService from './DataService'

export default {
  async postLogoutUser(token) {
    const response = await DataService.post(
      process.env.VUE_APP_API_BASE_URL + '/logout_user',
      {
        token
      }
    )
    if (!response || response.errorCode) {
      return false
    }
    try {
      // eslint-disable-next-line no-unused-vars
      const responseONEID = await DataService.post(response.oneidLogoutUrl, {})
    } catch (err) {
      console.error(err)
    }
    // try {
    //   // eslint-disable-next-line no-unused-vars
    //   const responseSA = await DataService.post(response.saLogoutUrl, {})
    // } catch (err) {
    //   console.error(err)
    // }
    return response.saLogoutUrl // Return safe avevue url to logout without ajax until ajax works
  }
}
