import DataService from './DataService'

export default {
  async postLoginWithToken(token) {
    const response = await DataService.post(
      process.env.VUE_APP_API_BASE_URL + '/login_with_token',
      {
        token
      }
    )
    if (!response || response.errorCode) {
      console.error(response)
      return false
    }
    return true
  }
}
